<template>
  <div>
    <!-- 条件 -->
    <el-row style="margin-bottom: 20px">
      <el-button type="danger" style="margin-bottom: 20px">批量删除</el-button>
      <el-form :inline="true" :model="params">
        <el-form-item label="姓名" class="formItemBoxStyle">
          <el-input v-model="params.aaaa"></el-input>
          <!-- <template slot="prepend">姓名</template> -->
        </el-form-item>
        <el-form-item label="学号/编号" class="formItemBoxStyle">
          <el-input v-model="params.bbbb"></el-input>
        </el-form-item>
        <el-form-item label="座位号" class="formItemBoxStyle">
          <el-select v-model="params.aaaa" placeholder="请选择">
            <!-- <el-option  v-for="item in options"  :key="item.value"  :label="item.label"  :value="item.value"> </el-option> -->
            <el-option :label="1" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select v-model="params.aaaa" placeholder="请选择">
            <!-- <el-option  v-for="item in options"  :key="item.value"  :label="item.label"  :value="item.value"> </el-option> -->
            <el-option :label="1" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实验" class="formItemBoxStyle">
          <el-select v-model="params.aaaa" placeholder="请选择">
            <!-- <el-option  v-for="item in options"  :key="item.value"  :label="item.label"  :value="item.value"> </el-option> -->
            <el-option :label="1" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="AI模式" class="formItemBoxStyle">
          <el-select v-model="params.aaaa" placeholder="请选择">
            <!-- <el-option  v-for="item in options"  :key="item.value"  :label="item.label"  :value="item.value"> </el-option> -->
            <el-option :label="1" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" size="medium">查询</el-button>
        <el-button size="medium">重置</el-button>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        type="index"
        align="center"
        width="55"
        label="序号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="a"
        label="实验名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="70"
        label="姓名"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        label="学号/编号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="70"
        label="座位号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="70"
        label="科目"
      ></el-table-column>
      <el-table-column align="center" prop="b" label="实验"></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="100"
        label="AI模式"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        label="操作时间"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="70"
        label="总得分"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        width="100"
        label="实验耗时"
      ></el-table-column>
      <el-table-column align="center" label="评分点" width="80"
        ><template slot-scope="scope"
          ><el-button type="text">查看</el-button></template
        ></el-table-column
      >
      <el-table-column align="center" label="录像" width="80"
        ><template slot-scope="scope"
          ><el-button type="text">查看</el-button></template
        ></el-table-column
      >
      <el-table-column align="center" label="实验报告" width="80"
        ><template slot-scope="scope"
          ><el-button type="text">查看</el-button></template
        ></el-table-column
      >
      <el-table-column align="center" label="操作" width="80"
        ><template slot-scope="scope"
          ><el-button type="text" style="color: #f56c6c"
            >删除</el-button
          ></template
        ></el-table-column
      >
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'AITeachExamsManage',
  data() {
    return {
      // 条件
      params: {
        aaaa: '',
        bbbb: '',
        cccc: '',
      },
      tableData: [
        {
          a: '1',
          b: '2',
          c: '3',
          d: '4',
          e: '5',
          f: '6',
          g: '7',
          h: '8',
          i: '9',
        },
      ],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 5,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 1,
      },
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      // this.sendAsk()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      // this.sendAsk()
    },
  },
  filters: {
    subjectFilter(key, font = '') {
      switch (key) {
        case 1:
          return `物理${font}`
        case 2:
          return `生物${font}`
        case 3:
          return `化学${font}`
        default:
          return `未知学科${font}`
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
<style lang="scss"></style>
